import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

const TOKEN_KEY = "auth_token";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  authenticationState = new BehaviorSubject(false);
  shareDataSubject = new Subject<any>();

  public backend_url = environment.backend_url;
  public headers = {};
  private token = "";



  constructor(
    public http:HttpClient,

    private router: Router
    )
     {

      }





  public isAuthenticated() {
    if(this.token){
      console.log("true");

      return true
      
    }
    else{
      console.log("false");

      return false
    }

  }

  getLocalStorage(){
    this.token = localStorage.getItem("auth_token");
  }


  public signInData(payload) {
    this.getLocalStorage()
    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
      
    };
    return this.http.post(
      this.backend_url + "/login",
      payload,
      options
    )


  }

  public resisterData(payload) {
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/signup/step-one",
      payload,
      options
    );
  }

  postpersonaldata(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/signup/step-two",
      payload,
      options
    );
  }


  public postAddressData(payload) {
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/signup/step-three",
      payload,
      options
    );
  }


  public postForgotPasswordEmail(payload) {
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/forget-password",
      payload,
      options
    );
  }


  public postResetPasssword(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/forget-password/reset",
      payload,
      options
    );
  }


  getlogout() {
    this.getLocalStorage()


    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.get(this.backend_url + "/logout", options);
  }



  //  autologIn():boolean{
  //   const token = localStorage.getItem('auth_token')
  //   console.log(token , "token");
  //   if(token){

  //   }
  //   return !!token
  // }

  
  
  dataToBeFiltered(obj) {
    this.shareDataSubject.next(obj);
  }  

  getFilterData(): Observable<any> {  
    return this.shareDataSubject.asObservable();  
  }


  verifyOTP(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer" + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/signup/otp-verify",payload,options
    );
  }


  GetOTP(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer" + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/signup/send-otp",payload,options
    );
  }
}





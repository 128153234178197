import { Injectable } from '@angular/core';
import { HttpClient , HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  public backend_url = environment.backend_url;
  private token = "";

  constructor( public http:HttpClient) {
   }




  public putUserProfileData(payload) {
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.put(
      this.backend_url + "/user/update-profile",
      payload,
      options
    );
  }

  putUpdatePassword(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.put(
      this.backend_url + "/user/update-password",
      payload,
      options
    );
  }


  putUpdateMasterKey(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.put(
      this.backend_url + "/user/update-master-key",
      payload,
      options
    );
  }





  getUserData() {

    
    this.getLocalStorage();
    console.log("oprating user data api");

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.get(
      this.backend_url + "/user/profile",
      options
    );
  }

  getLocalStorage(){
    this.token = localStorage.getItem("auth_token");
  }


  postUploadImage(payload){
    this.getLocalStorage()

    let params = new HttpParams();
    params += payload;
    console.log(this.token);
    
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/user/upload-profile-image",
      options,
      payload
    );
  }


  uploadProfile(payload){
    this.getLocalStorage();
    console.log("oprating user data api");

    let params = new HttpParams();
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/user/upload-profile-image",payload,
      options
    );
  }


  
  postPic(payload){
    this.getLocalStorage();
    let params = new HttpParams();
    params += payload;
    let options = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.token,
        Accept: "application/json",
      }),
      params: params,
    };
    return this.http.post(
      this.backend_url + "/user/upload-profile-image",
      payload,
      options
    );
  }


}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backend_url:'https://stagingapi.syncpays.com/api',
  // USERDATA_KEY: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiNWY4YmEzZmQxOTQxNzYyZDM0OGI5ZmVhYzZkMzIyMTQ5ZWQwYzUzZGI2YjA3ZDg4ZmEyMjViMzkyOTI1NWM4NWYzNDcxYmU0MWE1MTkxNzQiLCJpYXQiOjE2NjA3MTYxNjcuNjM2NjQ4LCJuYmYiOjE2NjA3MTYxNjcuNjM2NjUxLCJleHAiOjE2OTIyNTIxNjcuNjMyMzM4LCJzdWIiOiI0Iiwic2NvcGVzIjpbXX0.XznF1BYe4xlVBmMrHm5D1wpjvD9L_bOA5_96YqVVI7WOKx8ZD4JAnScyPsWYUy8P9dgkxIUrgxf1a_Apaaalcvq5TB0bmCD56M9UBbS5Xvo5b1wqFk113lkBZ-3rzB31LMHw_6Sn56V08XdZ4owWX6UnqRXhXx-BFgWHlkJbgVzXo3nunLUlNRbI26dfK6E-ORpFQ8QIVjUL1-JnA_jECU7jA1zBe7sSq6_-wdVnhKA9s--QNGXEtM0MEy9_zgxQSRKJ90e7umaD30jeAVe9ZZ_3tmhY8trwZQcU_OhzHTL4iPezrM512qou6o-75zfP6n6EwhA49o6AiQ8UjPJ1FWwXlTlfZghQtG_yWaOEJtvEWo79-YoZ0RhHOgfUQY1YdV4dH8nrGzNjnHna4g1cmfA0sc-r9NmxE6yvG0sFS3dF2RaJe8KMBoBoWxCvCqwkHSePo-mwrb-VZW8ZgVmCMmkKS5s5Wjqef-JvaxZqF7zmnzZtODHS03v7rez9mXIb4GEqXv9sIYlqFFE7WJleHljezRZBqkqT4tM_2f9Tw54rwxF4y6VkLaTOg7isbBUsiq5JqTMLtjAz5Z2mO3C34Wxd4BP1Zs9k4yBeYL707thkSG-AJqsJv9g4AhRcs1lX2VsiQ127DYt5ozIBxe9QUFBlM4rH3Bp1Q2pZyny12HQ',

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './api/auth-guard.service';
import { AppComponent } from './app.component';


const routes: Routes = [

  {
    path: '',
    redirectTo: '',
    pathMatch:'full'
  },
  {
    path: '',
    loadChildren: () => import('./sign-in/sign-in.module').then( m => m.SignInPageModule),

  },
  // {
  //   path: 'Home',
  //   loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  //   // canActivate: [AuthGuardService],

  // },
  {
    path: 'notification',
    loadChildren: () => import('./notification/notification.module').then( m => m.NotificationPageModule)
  },
  {
    path: 'Home',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule),
  },
  {
    path: 'ledger',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule),

  },
  {
    path: 'addFunds',
    loadChildren: () => import('./tab3/tab3.module').then(m => m.Tab3PageModule),

  },
  {
    path: 'sendFunds',
    loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule),

  },





 

  // {
  //   path: 'ledger',
  //   loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule),
  //   canActivate:[AuthGuard]
  // },
  // {
  //   path: 'add',
  //   loadChildren: () => import('./tab3/tab3.module').then(m => m.Tab3PageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'send',
  //   loadChildren: () => import('./tab4/tab4.module').then( m => m.Tab4PageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'withdraw-funds',
  //   loadChildren: () => import('./withdraw-funds/withdraw-funds.module').then( m => m.WithdrawFundsPageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'payee-list',
  //   loadChildren: () => import('./payee-list/payee-list.module').then( m => m.PayeeListPageModule),
  //   canActivate:[AuthGuard]
  // },
  // {
  //   path: 'my-account',
  //   loadChildren: () => import('./my-account/my-account.module').then( m => m.MyAccountPageModule),
  //   canActivate: [AuthGuard]
  // },
  // {
  //   path: 'request-money',
  //   loadChildren: () => import('./request-money/request-money.module').then( m => m.RequestMoneyPageModule)
  // },



];
@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
